import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem, Select } from '@mui/material'

const Organizations = ({ t }) => {
  const organizations = useSelector(state => state.userLayers.organizations.data)
  // for now we always have an array of 1 organization
  const [organizationName] = useState(organizations[0].name)

  const organizationSelect = (
    <Select sx={{ m: 1, minWidth: 120 }} labelId="demo-select-small-label" id="demo-select-small" value={organizationName} label="Username">
      <MenuItem value={organizationName}>{organizationName}</MenuItem>
    </Select>
  )

  return organizations && organizations[0].name ? organizationSelect : <></>
}

export default Organizations
