import React from 'react'
import { Tooltip } from '@mui/material'

export const StyledTooltip = ({ title, placement, followCursor = false, open, children, arrow }) => {
  return (
    <Tooltip title={title} placement={placement} followCursor={followCursor} open={open} arrow={arrow}>
      <div>{children}</div>
    </Tooltip>
  )
}
