import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import { getToken } from '../userStoredData'
import { getUserId } from '@layers-frontend/commons/store/selectors/user'

export const getConfig = get('config')

export const getIsAppReady = pipe(getConfig, get('isAppReady'))

export const getIsDashboardReady = pipe(getConfig, get('isDashboardReady'))

export const getMetadatasDateRange = pipe(getConfig, get('metadatasDateRange'))

export const getConfigAvailableLocales = pipe(getConfig, get('i18n.availableLocales'))

export const getConfigProjection = pipe(getConfig, get('projection'))

export const getConfigSample = pipe(getConfig, get('sampleConfig'))

export const getConfigData = (state, key) => pipe(getConfig, get(key))(state)

export const getEOXXML = pipe(getConfig, get('eoxXML'))

export const getMicroserviceSpecsUrl = state => {
  const userToken = getToken()
  const url = getConfigData(state, 'microserviceSpecsUrl')
  return `${url}/?api_key=${userToken}`
}

export const getFileGeneratorClassesUrl = state => {
  const userToken = getToken()
  const url = getConfigData(state, 'baseFileGeneratorUrl')
  return `${url}/classes?api_key=${userToken}`
}

export const getFileGeneratorPdfUrl = state => {
  const userToken = getToken()
  const url = getConfigData(state, 'baseFileGeneratorUrl')
  return `${url}/pdf?api_key=${userToken}`
}

export const getFileGeneratorShpUrl = state => {
  const userToken = getToken()
  const url = getConfigData(state, 'baseFileGeneratorUrl')
  return `${url}/shp?api_key=${userToken}`
}

export const getFileGeneratorRawUrl = state => {
  const userToken = getToken()
  const url = getConfigData(state, 'baseFileGeneratorUrl')
  return `${url}/raw?api_key=${userToken}`
}

export const getMonitoringExcelUrl = state => {
  const userToken = getToken()
  const url = getConfigData(state, 'baseMonitoringGenerationUrl')
  return `${url}?api_key=${userToken}`
}

export const getBaseEvalscriptUrl = state => {
  return getConfigData(state, 'baseEvalscriptUrl')
}

export const getSampleConfigByUserId = userId => pipe(getConfigSample, get(userId))

export const getSampleConfigPrefixByUserId = userId => pipe(getSampleConfigByUserId(userId), get('prefix'))

export const getSampleConfigCountByUserId = userId => pipe(getSampleConfigByUserId(userId), get('count'))

export const getSampleUniqueId = state => {
  const userId = getUserId(state)
  const prefix = getSampleConfigPrefixByUserId(userId)(state)
  const count = getSampleConfigCountByUserId(userId)(state)
  return `${prefix}_${count}`
}

// download global files reports
export const getGlobalFilesDownloadUrl = state => {
  const userToken = getToken()
  const url = getConfigData(state, 'baseGlobalFileUrl')
  return `${url}/get_presigned_url?api_key=${userToken}`
}

// download all global files from S3
export const getGlobalFilesJson = state => {
  const userToken = getToken()
  const url = getConfigData(state, 'baseGlobalFileUrl')
  return `${url}/file_paths?api_key=${userToken}`
}

export const isViewerReady = state => {
  const viewerInitializationQueries = getConfigData(state, 'initializationQueries.viewer')
  const array = Object.values(viewerInitializationQueries)
  return array.every(Boolean)
}
