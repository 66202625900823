import React from 'react'
import AccountFilters from './AccountFilters'
import AccountItem from './AccountItem'
import styled from 'styled-components'
import { List } from '@mui/material'
import FavouriteInstructions from '../../assets/img/accounts/favourite_instructions.png'
import { useSelector, useDispatch } from 'react-redux'
import { updateFavourites } from '../../features/accounts/accountsSlice'
/* import { updatePolicy } from '../../features/api/utils'
import { usePutPoliciesMutation } from '../../features/api/accountsConfigApi' */

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  color: #c6c141;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 90%;
`

const Image = styled.img`
  width: auto;
  padding-left: 10px;
`
export const ACCOUNTS = {
  account: 'Accounts',
  favourite_accounts: 'Favourite Accounts'
}

const AccountsList = ({ t, accounts }) => {
  const dispatch = useDispatch()
  const organizations = useSelector(state => state.userLayers.organizations.data)
  const defaultLoginAccount = useSelector(state => state.accounts.defaultAccount.data)

  // const [putPolicies] = usePutPoliciesMutation() // use to update default login or favourites

  const favouriteAccounts = useSelector(state => state.accounts.favouriteAccounts.data)

  // should only be one default login account !
  /*   const updateDefaultLoginAccount = (id, policy) => {
    const action = defaultLoginAccount && id === defaultLoginAccount ? 'delete' : 'update'
    const policyModified = updatePolicy(policy, '39fd7414-8175-4de4-946a-5ee578857cfb', id, action) // key for default_login_account
    return putPolicies({ userId, policy: policyModified }) // it works, sends to backend, but backend responses with a different format and it does not work
  } */

  const toggleFavourite = id => {
    let updatedFavourites
    if (favouriteAccounts.includes(id)) {
      updatedFavourites = favouriteAccounts.filter(favId => favId !== id) // remove from fav
    } else {
      updatedFavourites = [...favouriteAccounts, id] // add to fav
    }
    dispatch(updateFavourites({ key: 'favouriteAccounts', payload: updatedFavourites }))
  }

  const hasFavourite = favouriteAccounts.length > 0

  const isFavourite = id => {
    return favouriteAccounts.includes(id)
  }

  const findOrganization = (id, orgs) => orgs.find(org => org.id === id)?.name

  const emptyFavoutiteList = (
    <span style={{ padding: '16px' }}>
      You can add favourite accounts to this section by clicking the star at the right of each account <Image src={FavouriteInstructions}></Image>
    </span>
  )

  return (
    <Container className="accounts-list-container">
      <List
        sx={{
          width: '100%',
          overflowY: 'hidden', //`{${hasFavourite} ? 'auto':'hidden'}`,
          overflowX: 'hidden',
          padding: '0 16px 0 0',
          maxHeight: '45%',
          minHeight: '100px',
          alignContent: 'center'
        }}
        key="favourites"
        subheader={<AccountFilters title={ACCOUNTS.favourite_accounts} t={t} />}
      >
        {hasFavourite
          ? accounts.map(
              account =>
                isFavourite(account.id) && (
                  <AccountItem
                    key={account.id}
                    account={account}
                    name={account.name}
                    organization={findOrganization(account.organization_id, organizations)}
                    // policy={findAccountPolicy(account.id, userAccountsConfig)}
                    onClickDefaultLoginAccount={() => {}} //need backend update==>updateDefaultLoginAccount(account.id, policy)}
                    onToggleFavourite={() => toggleFavourite(account.id)}
                    isFavourite={isFavourite(account.id)}
                    isDefaultLoginAccount={defaultLoginAccount === account.id}
                  />
                )
            )
          : emptyFavoutiteList}
      </List>
      <hr style={{ borderTop: '1px solid #c6c141', width: '100%' }} />
      <List
        sx={{ width: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '0 16px 0 0' }}
        key="accounts"
        subheader={<AccountFilters title={ACCOUNTS.account} t={t} />}
      >
        {accounts.map(
          account =>
            !isFavourite(account.id) && (
              <AccountItem
                account={account}
                key={account.id}
                name={account.name}
                organization={findOrganization(account.organization_id, organizations)}
                /* policy={findAccountPolicy(account.id, userAccountsConfig)} */
                onClickDefaultLoginAccount={() => {}} //need backend update==> updateDefaultLoginAccount(account.id, policy.policy)}
                onToggleFavourite={() => toggleFavourite(account.id)}
                isFavourite={isFavourite(account.id)}
                isDefaultLoginAccount={defaultLoginAccount === account.id}
              />
            )
        )}
      </List>
    </Container>
  )
}

export default AccountsList
