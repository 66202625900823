import React, { useCallback, useState } from 'react'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { withTranslator } from '../HOCComponents/withTranslator'
import withLocale from '../HOCComponents/withLocale'
import { UIStyles } from '../../theme'
import styled, { css } from 'styled-components'
import LanguageButtons from '../../../src/components/UserComponents/LanguageButtons'
import { ROUTES } from '../../constants'
import Routing from '../../Routing'
import { Collapse, ListItemButton, ListItemIcon, ListItemText, List, ClickAwayListener } from '@mui/material'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { pageViewed } from '../analytics/analytics'

import UserConfiguration from './UserConfiguration'

const { LOG_OUT_ROUTE } = ROUTES
const freshdeskUrl = process.env.REACT_APP_FRESHDESK_URL
const layersStatusUrl = process.env.REACT_APP_LAYERS_STATUS_URL

const topBarHeight = '60px'
const UserMenuWrapper = styled.div`
  z-index: 2;
  position: fixed;
  top: ${topBarHeight};
  right: 0;
  background: ${UIStyles.blueColor};
  border-bottom-left-radius: 17px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  min-width: 250px;
`

const SettingsButtonOverride = {
  padding: '10px 20px'
}

const SettingsContainer = styled(Collapse)`
  color: ${UIStyles.vomitColor};
  background: ${UIStyles.blueColor};
`

export const userMenuAnchorCss = css`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: inherit;
  letter-spacing: 1px;
  padding: 10px 0;
  text-transform: ${props => props.textTransform || 'capitalize'};

  &:hover {
    color: ${UIStyles.vomitColor};
    text-decoration: underline;
  }
`

export function UserMenuLink({ color, ...props }) {
  const StyledLink = styled(Link)`
    ${userMenuAnchorCss}
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    color: ${color || 'inherit'};
    &:focus {
      color: inherit;
    }

    &[disabled] {
      color: ${UIStyles.vomitColor};
      text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
    }
  `
  return <StyledLink {...props} />
}

const UsermenuLi = styled.li`
  padding: 0 20px;
  color: ${UIStyles.vomitColor};

  &:not(:last-child) {
    border-bottom: 1px solid #374a54;
  }
`

const SettingsLi = styled(UsermenuLi)`
  padding: 0;
`

const UserMenuLiTitle = styled(UsermenuLi)`
  background: ${UIStyles.blueColor};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 10px 20px;
`
const MenuItem = ({ testId, text, iconName, url, onMenuClose }) => {
  const handleClick = useCallback(() => {
    onMenuClose()
    window.open(url)
  }, [onMenuClose, url])

  return (
    <UsermenuLi data-testid={testId}>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <i className={iconName} style={{ color: `${themeColors.vomitColor}` }} />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </UsermenuLi>
  )
}

const MenuItemLogOut = ({ text, iconName }) => {
  return (
    <UsermenuLi>
      <UserMenuLink to={`/${LOG_OUT_ROUTE}`} onClick={() => pageViewed(`/${LOG_OUT_ROUTE}`)}>
        <ListItemIcon>
          <i className={iconName} style={{ color: `${themeColors.vomitColor}` }} />
        </ListItemIcon>
        {text}
      </UserMenuLink>
    </UsermenuLi>
  )
}
const UserMenu = ({ userName, locale, t, patchConfig, config, onMenuClose }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  return (
    <ClickAwayListener onClickAway={onMenuClose}>
      <UserMenuWrapper>
        <List>
          <UserMenuLiTitle>{userName}</UserMenuLiTitle>

          <UsermenuLi>
            <LanguageButtons />
          </UsermenuLi>

          <MenuItem
            testId="contact-button"
            text={t('Contact')}
            iconName="fa-regular fa-envelope"
            url={`mailto: support@hemav.com?subject=Agro / ${userName}`}
            onMenuClose={onMenuClose}
          />

          <MenuItem
            testId="doc-button"
            text={t('documentation')}
            iconName="fa-solid fa-book-open"
            url={`http://docs.${Routing.locationHostName}/${locale}`}
            onMenuClose={onMenuClose}
          />

          <MenuItem testId="support-button" text="Agrosupport" iconName="fa-solid fa-headset" url={freshdeskUrl} onMenuClose={onMenuClose} />

          <MenuItem testId="status-button" text={t('layers status')} iconName="fa-solid fa-heartbeat" url={layersStatusUrl} onMenuClose={onMenuClose} />

          <SettingsLi id="settings-menu">
            <ListItemButton sx={[SettingsButtonOverride]} onClick={() => setIsSettingsOpen(!isSettingsOpen)}>
              <ListItemIcon>
                <i className="fa-solid fa-gear" style={{ color: `${themeColors.vomitColor}` }} />
              </ListItemIcon>
              <ListItemText primary={t('settings')} />
              <i
                className={`fa-solid ${isSettingsOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                style={{ marginLeft: '100px', color: `${themeColors.vomitColor}` }}
              />
            </ListItemButton>
            <SettingsContainer in={isSettingsOpen}>
              <UserConfiguration patchConfig={patchConfig} config={config} />
            </SettingsContainer>
          </SettingsLi>
          <MenuItemLogOut text={t('logout')} iconName="fa-solid fa-sign-out-alt" />
        </List>
      </UserMenuWrapper>
    </ClickAwayListener>
  )
}

export default compose(withTranslator, withLocale)(UserMenu)
