import { combineReducers } from 'redux'
import { customers, user } from './users'
// eslint-disable-next-line camelcase
import { user_azure } from '@layers-frontend/commons/store/reducers/user'
import customersDeliverables from './customersDeliverables'

import messages from '@layers-frontend/commons/store/reducers/messages'

import {
  fields,
  selectedField,
  selectedPreviewField,
  selectedFields,
  fieldTypes,
  selectedSnapshotIdx,
  geometries,
  temporalSwipe,
  selectedFieldImage,
  flightGroups,
  irrigationTypes,
  fieldStats
} from './fields'
import UI from './UI'
import selectedFieldTabs from './selectedFieldTabs'
import { search, searchOptions } from './search'
import { screenView, baseMaps } from './viewmode'
import { config } from './config'
import editor from './editor'
import { notifications } from './notifications'
import { deliverableTypes } from './deliverables'

import { fieldSeasonForm } from './fieldSeasonForm'
import { tutorial } from './tutorial'
import { dashboard } from './dashboard'
import modal from './modal'

import meteorologicalData from './meteorologicalData'
import availableFields from './availableFields'
import filters from './filters'
import globalLayer from './globalLayer'

import { fieldComparisonStatistics, temporalEvolutionStatistics } from './statistics'

import { plants, plantImage } from './plants'
import { LOG_OUT } from '../actions/users'
import fieldsReportModal from './fieldsReportModal'
import { predTech } from './predTech'
import { seasons, fieldSeasonParameters } from './seasons'
import { snapshots } from './snapshots'
import { calendar } from './calendar'
import reports from './reports'
import seasonLayers from './seasonLayers'
import fieldsSeasonsTimeline from './fieldsSeasonsTimeline'
import samplesForm from './samplesForm'

import microserviceSpecs from './microserviceSpecs'

import requests from '@layers-frontend/commons/store/reducers/requests'
import fieldsTileOrder from '@layers-frontend/commons/store/reducers/fieldsTileOrder'
import reportsTypes from '@layers-frontend/commons/store/reducers/reportsTypes'
import reportsStatuses from '@layers-frontend/commons/store/reducers/reportsStatuses'
import sampleTypes from '@layers-frontend/commons/store/reducers/sampleTypes'
import { samples } from './samples'
import sampleParametersByType from '@layers-frontend/commons/store/reducers/sampleParametersByType'
import globalFiles from './globalFiles'
import { accountsApi } from '../features/api/accountsApi'
import accountsReducer from '../features/accounts/accountsSlice'
import { accountsConfigApi } from '../features/api/accountsConfigApi'
import accountsConfigReducer from '../features/accounts/configSlice'
import { localeSliceReducer } from '../features/locale/localeSlice'
import usersReducer from '../features/auth/authSlice'
import { usersApi } from '../features/api/authApiSlice'

const appReducers = combineReducers({
  // Add the generated RTK Query "API slice" caching reducer
  [accountsApi.reducerPath]: accountsApi.reducer,
  accounts: accountsReducer,
  [accountsConfigApi.reducerPath]: accountsConfigApi.reducer,
  accountsConfig: accountsConfigReducer,
  userLayers: usersReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  availableFields,
  baseMaps,
  config,
  customers,
  customersDeliverables,
  dashboard,
  deliverableTypes,
  editor,
  fieldComparisonStatistics,
  fields,
  fieldStats,
  fieldSeasonForm,
  fieldSeasonParameters,
  fieldsReportModal,
  fieldsSeasonsTimeline,
  fieldTypes,
  filters,
  flightGroups,
  geometries,
  globalLayer,
  globalFiles,
  irrigationTypes,
  locale: localeSliceReducer,
  messages,
  meteorologicalData,
  microserviceSpecs,
  modal,
  notifications,
  plantImage,
  plants,
  reports,
  reportsTypes,
  reportsStatuses,
  requests,
  samples,
  samplesForm,
  sampleTypes,
  sampleParametersByType,
  predTech,
  screenView,
  search,
  searchOptions,
  seasonLayers,
  seasons,
  selectedField,
  selectedFieldImage,
  selectedFields,
  selectedPreviewField,
  selectedSnapshotIdx,
  snapshots,
  temporalEvolutionStatistics,
  temporalSwipe,
  tutorial,
  UI,
  user,
  // eslint-disable-next-line camelcase
  user_azure,
  selectedFieldTabs,
  fieldsTileOrder,
  calendar
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  if (action.type === LOG_OUT) {
    const { locale, messages } = state
    const appInitialState = undefined

    return {
      ...appReducers(appInitialState, action),
      locale,
      messages
    }
  }

  return appReducers(state, action)
}
