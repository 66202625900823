import { fetchRequestWrapper, fetchDownloadableWrapper, setRequestFail } from './ui'
import { APPLICATION_JSON_HEADER, APPLICATION_JSON_HEADER_PROCESSING_MICROSERVICE } from '@layers-frontend/commons/constants'

import FileSaver from 'file-saver'

import {
  getFileGeneratorClassesUrl,
  getFileGeneratorShpUrl,
  getFileGeneratorPdfUrl,
  getMicroserviceSpecsUrl,
  getFileGeneratorRawUrl,
  getGlobalFilesDownloadUrl
} from '../selectors/config'

import { errorNotification, alertNotification } from './notifications'
import { trackEvent } from '../../src/components/analytics/analytics'
import { TRACK_EVENTS } from '../constants'

import get from 'lodash/get'
import includes from 'lodash/includes'
import toLower from 'lodash/toLower'

export const REQUEST_MICROSERVICE_SPECS = 'REQUEST_MICROSERVICE_SPECS'
export const RECEIVE_MICROSERVICE_SPECS = 'RECEIVE_MICROSERVICE_SPECS'
export const FETCH_MICROSERVICE_SPECS = 'FETCH_MICROSERVICE_SPECS'

export const REQUEST_LAYER_CLASSES = 'REQUEST_LAYER_CLASSES'
export const RECEIVE_LAYER_CLASSES = 'RECEIVE_LAYERS_CLASSES'
export const FETCH_LAYER_CLASSES = 'FETCH_LAYER_CLASSES'

export const DOWNLOAD_LAYER = 'DOWNLOAD_LAYER'
export const DOWNLOAD_LAYER_SUCCESS = 'DOWNLOAD_LAYER_SUCCESS'

export const fetchMicroserviceSpecs = () => (dispatch, getState) => {
  const state = getState()
  const microserviceSpecsUrl = getMicroserviceSpecsUrl(state)
  dispatch(
    fetchRequestWrapper({
      customRoute: microserviceSpecsUrl,
      requestType: REQUEST_MICROSERVICE_SPECS,
      fetchOptions: {
        method: 'GET',
        headers: APPLICATION_JSON_HEADER
      },
      onSuccess: receiveMicroserviceSpecsUrl
    })
  )
}

const receiveMicroserviceSpecsUrl = specs => ({
  type: RECEIVE_MICROSERVICE_SPECS,
  specs
})

export const fetchLayerClasses = ({ baseLayer, snapshots, mode, classes }) => (dispatch, getState) => {
  const state = getState()
  const customRoute = getFileGeneratorClassesUrl(state)
  const body = { snapshots, base_layer: toLower(baseLayer), mode, classes }
  return dispatch(
    fetchRequestWrapper({
      customRoute,
      requestType: REQUEST_LAYER_CLASSES,
      fetchOptions: {
        method: 'POST',
        headers: APPLICATION_JSON_HEADER,
        body: JSON.stringify(body)
      },
      onSuccess: receiveLayerClasses,
      overlay: false
    })
  )
}

const receiveLayerClasses = classes => ({
  type: RECEIVE_LAYER_CLASSES,
  classes
})

export const downloadLayer = ({
  fieldsNames,
  baseLayer,
  baseLayerName,
  mode,
  resolution = undefined,
  classes = undefined,
  filePrefix = '',
  surfaceUnit,
  language,
  snapshots,
  formatType,
  extension,
  name,
  maxRetry = 2,
  t
}) => (dispatch, getState) => {
  const state = getState()
  const fieldName = get(fieldsNames, '[0]')
  const date = get(snapshots, '[0].date')
  const fileName = `${filePrefix}_${baseLayerName}_${fieldName}_${date}.${extension}`

  let customRoute
  switch (formatType) {
    case 'pdf':
      customRoute = getFileGeneratorPdfUrl(state)
      break
    case 'shp':
      customRoute = getFileGeneratorShpUrl(state)
      break
    case 'raw':
      customRoute = getFileGeneratorRawUrl(state)
      break
    default:
      customRoute = getFileGeneratorPdfUrl(state)
      break
  }

  let body = {
    base_layer: toLower(baseLayer),
    file_name: filePrefix,
    resolution,
    language,
    snapshots,
    surface_unit: surfaceUnit,
    mode,
    value_name: name
  }

  if (classes && classes.length > 0) {
    body = {
      ...body,
      classes
    }
  }

  dispatch(
    fetchDownloadableWrapper({
      requestType: DOWNLOAD_LAYER,
      customRoute,
      onSuccess: blob => {
        trackEvent(TRACK_EVENTS.VIEWER_FILE_GENERATED, { baseLayer, formatType, language, resolution })
        return downloadLayerSuccess(fileName)(blob)
      },
      fetchOptions: {
        method: 'POST',
        headers: APPLICATION_JSON_HEADER_PROCESSING_MICROSERVICE,
        body: JSON.stringify(body)
      }
    })
  ).catch(error => {
    if (includes(get(error, 'message'), '404')) {
      dispatch(errorNotification(error.message))
    }
    if (includes(get(error, 'message'), '400')) {
      dispatch(errorNotification(t('File cannot be generated due to high amounts of clouds. Please select another date.')))
    }
    if (includes(get(error, 'message'), '422')) {
      dispatch(alertNotification(t('file-is-not-available-in-selected-language')))
      if (maxRetry === 0) return
      dispatch(
        downloadLayer({
          fieldsNames,
          baseLayer,
          baseLayerName,
          resolution,
          classes,
          filePrefix,
          language: 'en',
          mode,
          snapshots,
          formatType,
          extension,
          maxRetry: maxRetry - 1,
          t
        })
      )
    }
  })
}

//global files reports
export const downloadGlobalFile = (payload, fileName) => (dispatch, getState) => {
  const state = getState()
  const customRoute = getGlobalFilesDownloadUrl(state)

  dispatch(
    fetchRequestWrapper({
      requestType: DOWNLOAD_LAYER,
      customRoute,
      onSuccess: downloadLayerSuccess(fileName),
      fetchOptions: {
        method: 'POST',
        headers: APPLICATION_JSON_HEADER_PROCESSING_MICROSERVICE,
        body: JSON.stringify(payload)
      },
      onError: error => {
        dispatch(setRequestFail)
        console.log('error', error)
      }
    })
  )
}

const downloadLayerSuccess = fileName => blob => dispatch => {
  FileSaver.saveAs(blob, fileName)
  // todo: track time to download
  dispatch({
    type: DOWNLOAD_LAYER_SUCCESS
  })
}
