import React from 'react'
import styled from 'styled-components'
import Top from '../../assets/img/accounts/arrow-top-default.svg'
import TopHover from '../../assets/img/accounts/arrow-top-hover.svg'
import TopSelected from '../../assets/img/accounts/arrow-top-selected.svg'
import Favourite from '../../assets/img/accounts/favourite-default.svg'
import FavouriteHover from '../../assets/img/accounts/favourite-hover.svg'
import FavouriteSelected from '../../assets/img/accounts/favourite-selected.svg'
import IconButton from '../UIComponents/IconButton'
import { StyledTooltip } from '../UIComponents/StyledTooltip'

const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 80px;
`

const ActionIcons = ({ t, onClickDefaultLoginAccount, onToggleFavourite, isFavourite, isDefaultLoginAccount }) => {
  return (
    <ActionsContainer>
      <StyledTooltip
        title={
          isDefaultLoginAccount ? (
            t(
              'Remove the default account (Coming Soon): Currently, you can view your accounts. The option to set or remove a default will be available in a future update.'
            )
          ) : (
            /*  t('Click to remove this as your default account') */
            <div style={{ width: 300, whiteSpace: 'normal', textAlign: 'left', left: 300, padding: 10 }}>
              <span /* style={{ fontSize: 12, fontWeight: 400 }} */>
                {t(
                  'Set as default account (Coming Soon): Currently, you can view your accounts. The option to set or remove a default will be available in a future update.'
                )}
                {/* {t('Click to set this as your default account')} */}
              </span>
              <br />
              <br />
              {t('When you log in, you’ll be taken to this account’s map view by default. You can change your default account here at any time.')}
              <br />
            </div>
          )
        }
        placement="left"
      >
        <IconButton
          defaultIcon={Top}
          hoverIcon={TopHover}
          selectedIcon={TopSelected}
          onClick={onClickDefaultLoginAccount}
          selected={isDefaultLoginAccount}
          isDisabled={!isDefaultLoginAccount}
        />{' '}
      </StyledTooltip>
      <StyledTooltip title={isFavourite ? t('Remove from favourites') : t('Add to favourites')} placement="top">
        <IconButton defaultIcon={Favourite} hoverIcon={FavouriteHover} selectedIcon={FavouriteSelected} onClick={onToggleFavourite} selected={isFavourite} />
      </StyledTooltip>
    </ActionsContainer>
  )
}

export default ActionIcons
