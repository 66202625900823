// prettier-ignore
export default {
  "% Fallas": "% Hiba",
  "% Mala Hierba": "% Gyom",
  "ACELGA": "MÁNGOLD",
  "ACELGA VERDE": "ZÖLD MÁNGOLD",
  "action": "akció",
  "ag_accesses_the_viewer": "k. AG hozzáfér a megjelenítőhöz?",
  "AGUACATE": "AVOKÁDÓ",
  "AJO": "FOKHELYGM",
  "ALBARICOQUE": "SÁRGABARACK",
  "ALBARINO": "ALBARINO",
  "ALFALFA": "LUCERNA",
  "ALGODON": "PAMUT",
  "aluminum": "Alumínium",
  "aluminum_saturation": "Alumínium Telítettség",
  "annotation": "megjegyzés",
  "aphid_controller": "levéltetű - vezérlő",
  "aphid_infested": "levéltetű - fertőzött",
  "aphid_infested_leafs": "levéltetű - fertőzött levelek",
  "aphid_mite_total_leafs": "levéltetű és atka - összes levelek",
  "APIO": "ZELLER",
  "apparent_density": "Látszólagos sűrűség",
  "ARANDANOS": "ÁFONYA",
  "ARBOSANA": "ARBOSANA",
  "ARROZ": "RIZS",
  "assesor_name": "Tanácsadó neve",
  "atr": "Atr",
  "AVENA": "ZAB",
  "BARBECHO": "TARLÓ",
  "BATAVIA": "BATAVIA",
  "beans_10_plants": "10 bab növény",
  "billaea_healthy_pupe": "billaea - egészséges bábok",
  "billaea_larva": "billaea - lárvák",
  "billaea_parasitized_larva": "billaea - parazitált lárvák",
  "billaea_residual_pupe": "billaea - maradék bábok",
  "bipolaris_sacchari_incidence": "bipolaris sacchari \"szemfolt\" - előfordulás",
  "bipolaris_sacchari_severity": "bipolaris sacchari \"szemfolt\" - súlyosság",
  "bipolaris_sacchari_total_infected_leafs": "bipolaris sacchari \"szemfolt\" - összes fertőzött levelek",
  "BLANCO": "FEHÉR",
  "blastobasis_healthy_chrysalis": "blastobasis - egészséges bábok",
  "blastobasis_larva_number": "blastobasis - lárvák",
  "blastobasis_residual_chrysalis": "blastobasis - maradék báb",
  "boron": "Bór",
  "bouquet10roots_2_8cm": "02.Bouquet 10 gyökér (2-8cm)",
  "bouquet10roots_2cm": "01.Bouquet 10 gyökér (0-2cm)",
  "bouquet10roots_8cm": "03.Bouquet 10 gyökér (>8cm)",
  "brix": "Brix",
  "BROCOLI": "BROKKOLI",
  "bud_blastobasis_drilled": "diatraea blastobasis fúrt rügyek",
  "bud_dehydrated": "kiszáradt rügyek",
  "bud_diatrea_drilled": "fúrt diatraea rügyek",
  "bud_mechanical_damage": "rügyek mechanikai károsodása",
  "bud_sprout": "rügyek - hajtások",
  "bud_total": "összes rügyek",
  "bud_turgid": "duzzadt rügyek",
  "CABERNET SAUVIGNON": "CABERNET SAUVIGNON",
  "CALABACIN": "CUKKINI",
  "calcium": "Kálcium",
  "calcium_saturation": "Kalcium Telítettség",
  "CAÑA DE AZUCAR": "CUKORNÁD",
  "CAQUI": "KAKI",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Felnőttek száma 50 növényben",
  "casida_n_of_eggs_in_50_plants": "Cásida - Tojások száma 50 növényben",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Nagy lárvák száma 50 növényben",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Kis lárvák száma 50 növényben",
  "CAUCHO": "GUMI",
  "CEBADA": "ÁRPA",
  "CEBADA HIBRIDA": "HIBRID ÁRPA",
  "CEBADA MALTERA": "MALTERA ÁRPA",
  "CEBOLLA": "HAGYMA",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 folt/levél",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% levelek kiterjedése 80-100% súlyossággal",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Egészséges növény",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Egyedi foltok néhány leveleken (10-50%SL/100 levelek).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Egyedi foltok néhány leveleken (5-10%SL/100 levelek)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Egyedi foltok néhány leveleken (<5%SL/100 levelek)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Levél nekrózis EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Levelek EXT: majdnem teljes nekrózis",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Levelek több mint 100 folt",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 folt/levél",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Nekrózis a legtöbb leveleken INT.",
  "cercospora_treatment": "Cercospora -o- Kezelés",
  "CEREZA": "CIROK",
  "CESPED": "GYEP",
  "CHARDONNAY": "CHARDONNAY",
  "chromium": "Króm",
  "cic": "Cic",
  "CIRUELA": "SZILVA",
  "clay": "Agyag",
  "CLEMENTINA": "KLEMENTIN",
  "close": "bezár",
  "COLZA": "REPCE",
  "Conteo": "Számlálás",
  "copper": "Réz",
  "cotesia_cocon": "cotesia - kokonok",
  "cotesia_parasitized_larva": "cotesia - parazitált lárvák",
  "cotyledon": "kotiledon",
  "current_ndre_status": "a. Jelenlegi NDRE állapot",
  "date_of_first_informative_email_sent": "j. Az első tájékoztató e-mail elküldésének dátuma",
  "date_of_hemav_ndre_layer_screenshot": "h. A Hemav NDRE réteg képernyőképének dátuma",
  "defoliator_leaf_evaluated": "defoliátor - értékelt levelek",
  "defoliator_marasmia_sp_larva_alive": "defoliátor - marasmia sp. - élő lárvák",
  "defoliator_marasmia_sp_larva_death": "defoliátor - marasmia sp. - halott lárvák",
  "defoliator_marasmia_sp_larva_per_stem": "defoliátor - marasmia sp. - lárvák száraonként",
  "defoliator_mythimna_sp_larva_alive": "defoliátor - mythimna sp. - élő lárvák",
  "defoliator_mythimna_sp_larva_death": "defoliátor - mythimna sp. - halott lárvák",
  "defoliator_mythimna_sp_larva_per_stem": "defoliátor - mythimna sp. - lárvák száraonként",
  "defoliator_spodoptera_sp_larva_alive": "defoliátor - spodoptera sp. - élő lárvák",
  "defoliator_spodoptera_sp_larva_death": "defoliátor - spodoptera sp. - halott lárvák",
  "defoliator_spodoptera_sp_larva_per_stem": "defoliátor - spodoptera sp. - lárvák száraonként",
  "defoliator_stem_total": "defoliátor - összes szár",
  "depth": "Mélység",
  "desconocido": "Ismeretlen",
  "DESCONOCIDO": "ISMERETLEN",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - nagy lárvák",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - egészséges bábok",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - közepes lárvák",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - maradék báb",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - kis lárvák",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - összes lárvák",
  "disease": "Betegség",
  "Disease": "Betegség",
  "disease_level": "08.Betegség szint",
  "distance_between_rows": "03.Sorok közötti távolság",
  "does_it_follow_the_recommendation": "f. Követi az ajánlást?",
  "Drip": "Csepegtető",
  "dry_weight": "Száraz tömeg",
  "effective_cation_exchange_capacity": "Effektív Kationcsere Kapacitás (EKC)",
  "ESCAROLA": "ENDÍVIA",
  "ESCAROLA LISA": "SIMA ENDÍVIA",
  "ESCAROLA RIZADA": "GÖNDÖR ENDÍVIA",
  "ESPARRAGO": "SPÁRGA",
  "ESPINACA": "SPENÓT",
  "estrés hídrico": "Vízstressz",
  "Estrés hídrico (LWS)": "Vízstressz (LWS)",
  "evaluator": "10.Értékelő neve",
  "Ex": "Ex.",
  "exchangeable_acidity": "Kicserélhető Savasság",
  "forecast_yield_sampling_1": "ELŐREJELZÉS HOZAM MINTAVÉTEL 1",
  "forecast_yield_sampling_2": "ELŐREJELZÉS HOZAM MINTAVÉTEL 2",
  "forecast_yield_sampling_3": "ELŐREJELZÉS HOZAM MINTAVÉTEL 3",
  "forecast_yield_sampling_4": "ELŐREJELZÉS HOZAM MINTAVÉTEL 4",
  "fresh_weight": "Friss tömeg",
  "FRIJOL": "BAB",
  "fruits_count": "Gyümölcsök száma",
  "FRUTAL": "GYÜMÖLCSÖS",
  "Generic": "Generikus",
  "GEWURZTRAMINNER": "GEWURZTRAMINNER",
  "GIRASOL": "NAPOZÓVIRÁG",
  "gm_avg_ndvi": "Vegetatív erély (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Vegetatív erély (NDVI-LUVI)",
  "gm_ndmi_mean": "Vegetatív Nedvesség (NDMI)",
  "gm_ndre_mean": "Nitrogén-Klorofill (NDRE)",
  "gm_perc_overseeding": "% Rések",
  "gm_perc_weeds": "% Gyom",
  "GODELLO": "GODELLO",
  "GOLDEN DELICIOUS": "GOLDEN DELICIOUS",
  "GOLDEN REINDERS": "GOLDEN REINDERS",
  "Grams": "Grammok",
  "GRANADA": "GRÁNÁTALMA",
  "green_dots": "zöld pontok",
  "GRENACHE BLANC": "GRENACHE BLANC",
  "GUISANTE": "BORsÓ",
  "HABA": "BAB",
  "height": "Magasság",
  "HIBRIDO": "HIBRID",
  "high irrigation": "Magas vízstressz",
  "high_n_from_irrigation_water": "d. Magas N az öntözővízből",
  "high_n_from_nitrogen_input": "e. Magas N a nitrogén bevitelből",
  "high_n_from_previous_years": "c. Magas N az előző évekből",
  "higher level": "Magasabb szint",
  "HIGO": "FÜGE",
  "Humedad Vegetativa (NDMI) ": "Vegetatív Nedvesség (NDMI)",
  "humidity": "Nedvesség",
  "humidity_100_beans": "Nedvesség 100 babban",
  "Imperial": "Birodalmi",
  "inspection": "ellenőrzés",
  "internodes_blastobasis_damage_longitude": "internódiumok blastobasis hosszú károsodása",
  "internodes_blastobasis_drilled": "fúrt blastobasis internódiumok",
  "internodes_diatraea_damage_longitude": "internódiumok diatrea hosszú károsodása",
  "internodes_diatraea_drilled": "fúrt diatrea internódiumok",
  "internodes_evaluated": "összes internódiumok",
  "internodes_healthy": "egészséges internódiumok",
  "internodes_mechanical_damage": "internódiumok mechanikai károsodása",
  "internodes_physiological_damage": "internódiumok fiziológiai károsodása",
  "iron": "Vas",
  "irrigation": "ÖNTÖZÉS",
  "Irrigation": "Öntözés",
  "irrigation_dosage": "Öntözési Adag",
  "irrigation_uniformity": "Öntözési Egyenletesség",
  "KUMQUATS": "KUMKVATOK",
  "language": "Rendszer nyelve",
  "larva_number_big": "lárvastádium - nagy lárvák",
  "larva_number_medium": "lárvastádium - közepes lárvák",
  "larva_number_small": "lárvastádium - kis lárvák",
  "last_fertilizer_app_date": "b. Utolsó műtrágya alkalmazás dátuma",
  "last_irrigation": "Utolsó Öntözés",
  "lead": "Ólom",
  "Leaf": "Levél",
  "leaf_moisture_status": "Levél Nedvesség Állapota",
  "LECHUGA": "SALÁTA",
  "LECHUGA ICEBERG": "ICEBERG SALÁTA",
  "leptosphaeria_sacchari_incidence": "leptosphaeria sacchari \"gyűrűfolt\" - előfordulás",
  "leptosphaeria_sacchari_severity": "leptosphaeria sacchari \"gyűrűfolt\" - súlyosság",
  "leptosphaeria_sacchari_total_infected_leafs": "leptosphaeria sacchari \"gyűrűs folt\" - összes fertőzött levelek",
  "LIMON": "CITROM",
  "linear_meters": "02.Lineáris méterek",
  "loam": "Agyagtalaj",
  "low irrigation": "Alacsony vízstressz",
  "lower level": "Alsó szint",
  "lowered_unload": "03.Csökkentett kisütés",
  "MACABEU": "MACABEU",
  "MACABEU VIURA": "MACABEU VIURA",
  "magnesium": "Magnézium",
  "magnesium_saturation": "Magnézium Telítettség",
  "MALVASIA AROMATICA": "AROMATIKUS MALVASIA",
  "MALVASIA DE SITGES": "MALVASIA DE SITGES",
  "MALVASIA GROSSA": "MALVASIA GROSSA",
  "MANDARINA": "MANDARIN",
  "manganese": "Mangán",
  "MANZANA": "ALMA",
  "mean level": "Átlagos szint",
  "medium irrigation": "Közepes vízstressz",
  "medium_moisture_saturation": "Közepes Nedvességtelítettség",
  "MELOCOTON": "ŐSZIBARACK",
  "MELON": "SÁRGA DINNYE",
  "mercury": "Higany",
  "MERLOT": "MERLOT",
  "Metric": "Metrikus",
  "microorganisms_larva": "lárvák mikroorganizmusok",
  "mite_grade": "atka - fokozat",
  "mite_infested": "atka - fertőzött",
  "mite_infested_leafs": "atka - fertőzött levelek",
  "moisture_status": "07.Nedvesség Állapota",
  "molybdenum": "Molibdén",
  "MONTONEC": "MONTONEC",
  "MONTONEGA": "MONTONEGA",
  "MOSCATELL DE ALEXANDRIA": "MOSCATELL ALEXANDRIA",
  "MOSCATELL ROMA": "MOSCATELL RÓMA",
  "NARANJA": "NARANCS",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "NEKTRIN",
  "NECTARINA": "NEKTARIN",
  "nickel": "Nikkel",
  "Nitrogen": "Nitrogén",
  "Nitrógeno Foliar": "Levél Nitrogén",
  "nitrógeno-clorofila (ndre)": "Nitrogén-Klorofill (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Nitrogén-Klorofill (NDRE)",
  "No crop or too dry": "Nincs termés vagy túl száraz",
  "NO CULTIVO": "NEM KULTÚRA",
  "No hay datos": "Nincsenek adatok",
  "NOGAL": "DIÓFA",
  "notification_to_the_farmer_high_n_index": "g. Értesítés a gazdának: Magas N index",
  "Observations": "Megfigyelések",
  "OLIVO": "OLAJFA",
  "opogona_larva_number": "opogona - lárvák",
  "organic_material": "Szerves anyag",
  "organic_oxidizable_carbon": "Oxidálható Szerves Szén",
  "PANSA BLANCA": "FEHÉR PANSA",
  "PANSAL": "PANSAL",
  "PARAGUAYO": "PARAGUAYI",
  "PARELLADA": "PARELLADA",
  "PATATA": "BURGONYA",
  "pattern": "mintázat",
  "PERA": "KÖRTE",
  "percent_disease": "Százalékos betegség",
  "perkinsiella_345_instar_nymph": "perkinsiella - nimfák 3-4-5 instar",
  "perkinsiella_adult": "perkinsiella - felnőttek",
  "perkinsiella_controller_coccinelidos": "perkinsiella - vezérlők - coccinelidák",
  "perkinsiella_controller_crysopas": "perkinsiella - vezérlők - crysopas",
  "perkinsiella_controller_spiders": "perkinsiella - vezérlők - pókok",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - vezérlők - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - vezérlők - zelus",
  "perkinsiella_death_adult": "perkinsiella - halott felnőttek",
  "perkinsiella_death_nymph": "perkinsiella - halott nimfák",
  "petiole_sampling": "i. Petíció mintavétel",
  "ph": "Ph",
  "pheromone_trap_diatraea_adults_number": "feromon csapda - felnőtt diatraea",
  "pheromone_trap_installation_date": "feromon csapda - telepítési dátum",
  "pheromone_trap_number": "feromon csapda - csapdák",
  "pheromone_trap_other_butterfly": "feromon csapda - más pillangók",
  "pheromone_trap_pheromone_change": "feromon csapda - feromon változás",
  "phosphorus": "Foszfor",
  "PIMIENTO": "PAPRIKA",
  "PINO": "FENYŐ",
  "PINOT NOIR": "PINOT NOIR",
  "PISTACHO": "PISTÁCIA",
  "Pivot": "Középpont",
  "Plague": "Pestis",
  "plague_level": "09.Pestis Szint",
  "Plant": "Növény",
  "Plant Density": "Növényi Sűrűség",
  "plantation_date": "Ültetés dátuma",
  "plants_per_surface_unit": "Növények felületi egységenként",
  "poda": "Metszés",
  "pol": "Pol",
  "POMELO": "POMELO",
  "POMELOS": "POMELOS",
  "potassium": "Kálium",
  "potassium_saturation": "Kálium Telítettség",
  "powdery_mildew_afa_perc": "Lisztgomba - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Lisztgomba - Érintett növények százaléka (ELŐFORDULÁS)",
  "powdery_mildew_treatment": "Lisztgomba - Kezelés",
  "Production": "Termelés",
  "pseudomonas_rubrilineans_stem_number": "vörös csík - összes szár",
  "pseudomonas_rubrilineans_stem_number_affected": "vörös csík - érintett szárak",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "vörös csík - tünetekkel rendelkező szárak",
  "puccinia_melanocephala_accumulated_scale": "rozsdagomba - felhalmozódott skála",
  "puccinia_melanocephala_infestation": "rozsdagomba-fertőzés",
  "puccinia_melanocephala_infested_leafs": "rozsdagomba-fertőzött levelek",
  "puccinia_melanocephala_points": "rozsdagomba - pontok",
  "puccinia_melanocephala_total_leafs": "rozsdagomba - összes levelek",
  "puccinia_sp_incidence": "puccinia sp \"rozsda\" - előfordulás",
  "puccinia_sp_severity": "puccinia sp \"rozsda\" - súlyosság",
  "puccinia_sp_total_infected_leafs": "puccinia sp \"rozsda\" - összes fertőzött levelek",
  "PUERRO": "PÓRÉHAAGYMA",
  "Quality": "Minőség",
  "QUINOA": "KINUÁ",
  "RAIGRAS": "RAIGRAS",
  "real_leaves": "valódi levelek",
  "REMOLACHA": "CÉKLA",
  "REMOLACHA AZUCARERA": "CUKORRÉPA",
  "resowing_surface": "Újravetés felülete",
  "result": "eredmény",
  "riego": "Öntözés",
  "rot10roots_complete": "03.Rot 10 gyökér - Teljes",
  "rot10roots_nothing": "01.Rot 10 gyökér - Semmi ",
  "rot10roots_partial": "02.Rot 10 gyökér - Részleges   ",
  "rust_10_25_afa": "Rozsda - 10-25 % AFA",
  "rust_100_afa": "Rozsda -N- 100 % AFA. Eredeti lombozat teljesen elpusztult",
  "rust_25_35_afa": "Rozsda -J- 25-35 % AFA. A lombozat 1/4 -1/3 része súlyosan érintett",
  "rust_3_10_afa": "Rozsda -I- 3-10 % AFA",
  "rust_35_50_afa": "Rozsda -K- 35-50 % AFA. A lombozat 1/3 - 1/2 része súlyosan érintett",
  "rust_50_75_afa": "Rozsda -L- 50-75 % AFA. A lombozat fele súlyosan érintett",
  "rust_75_90_afa": "Rozsda -M- 75-90 % AFA. Az összes lombozat súlyosan érintett",
  "rust_healthy_plant": "Rozsda -A- Egészséges növény",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Rozsda -B- Egyedi foltok néhány leveleken. 1-9 (%SL)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Rozsda -C- Egyedi foltok néhány leveleken. 10-19 (%SL)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Rozsda -D- Egyedi foltok néhány leveleken. 20-29 (%SL)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Rozsda -E- Egyedi foltok néhány leveleken. 30-50 (%SL)",
  "rust_regrowth_restart": "Rozsda -O- Újranövekedés kezdete. Adjon hozzá 0,5 pontot minden hétig a betakarításig",
  "rust_spots_leaves_100_300": "Rozsda -H- Levelek 100-300 folt",
  "rust_spots_leaves_20_100": "Rozsda -G- 20-100 folt levélenként a legtöbb leveleken",
  "rust_spots_leaves_50": "Rozsda -F- >50% SL és <20 folt levélenként a legtöbb leveleken",
  "rust_treatment": "Rozsda -p- Kezelés",
  "sac": "Sac",
  "Safety": "Biztonság",
  "sample_color": "05.Minta színe",
  "sand": "Homok",
  "SAUVIGNON BLANC": "SAUVIGNON BLANC",
  "severity": "Súlyosság",
  "soca_width": "04.Gyökerek száma",
  "sodium": "Nátrium",
  "sodium_saturation": "Nátrium Telítettség",
  "Soil": "Talaj",
  "soil_moisture_status": "Talaj Nedvesség Állapota",
  "SOJA": "SZÓJA",
  "sprout_dead": "elhalt hajtás",
  "sprout_total": "összes hajtás",
  "starch": "Keményítő",
  "stems_evaluated": "értékelt szárak",
  "sulfur": "Kén",
  "Superficial": "Felszíni",
  "SYRAH": "SYRAH",
  "TABACO": "DOHÁNY",
  "TE": "TEA",
  "TEA": "FÁKLYA",
  "TEMPRANILLO": "TEMPRANILLO",
  "TOMATE": "PARADICSOM",
  "total_arsenic": "Összes Arzén",
  "total_cadmium": "Összes Kadmium",
  "TOURIGA NACIONAL": "NEMZETI TOURIGA",
  "TRIGO": "BUZA",
  "TRIGO BLANDO": "PUHA BUZA",
  "TRIGO DURO": "KEMÉNY BUZA",
  "TRITICALE": "TRITIKÁLÉ",
  "type_disease": "Betegség típusa",
  "ustilago_scitaminea_infection": "ustilago scitaminea \"szén\" - fertőzés",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"szén\" - összes beteg szár",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"szén\" - tünetekkel rendelkező szárak",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"szén\" - összes szárak",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"szén\" - ostor szárak",
  "UVA": "SZŐLŐ",
  "UVA DE MESA": "ASZTALI SZŐLŐ",
  "Variabilidad": "Variabilitás",
  "Variabilidad (NDVI-LUVI)": "Variabilitás (NDVI-LUVI)",
  "VERDEJO": "VERDEJO",
  "very high irrigation": "Nagyon magas vízstressz",
  "very low irrigation": "Nagyon alacsony vízstressz",
  "VIGNE": "VIGNE",
  "Vigor Vegetativo (NDVI-Drone)": "Vegetatív vigor (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vegetatív vigor (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vegetatív vigor (NDVI) - Magas vigor",
  "VIÑA": "SZŐLŐSKERT",
  "VIOGNIER": "VIOGNIER",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "szűz nőstény georeferenciált csapda - összes felnőtt diatraea",
  "virgin_female_georeferenced_trap_female_change": "szűz nőstény georeferenciált csapda - nőstény változás",
  "virgin_female_georeferenced_trap_other_butterfly": "szűz nőstény georeferenciált csapda - más pillangók",
  "virgin_female_georeferenced_trap_point": "szűz nőstény georeferenciált csapda - csapdapont",
  "virgin_female_trap_diatraea_adult_number": "szűz nőstény csapda - diatraea felnőttek",
  "virgin_female_trap_female_change": "szűz nőstény csapda - nőstény változás",
  "virgin_female_trap_installation_date": "szűz nőstény csapda - telepítési dátum",
  "virgin_female_trap_number": "szűz nőstény csapda - csapdák",
  "virgin_female_trap_other_butterfly": "szűz nőstény csapda - más pillangók",
  "VIURA": "VIURA",
  "weed_unload": "01. Gyomok (kisütés)",
  "Weeds": "Gyomok",
  "weight_kg": "06.Minta súlya",
  "weight_100_beans": "100 bab növényei",
  "whey": "Savó",
  "without_footwear": "01.Cipő nélkül",
  "without_gloves": "04.Kesztyű nélkül",
  "without_vest": "02.Mellény nélkül",
  "XARELLO": "XARELLO",
  "yellowness_mild": "Sárgaság - Enyhe támadás (laza növények)",
  "yellowness_moderate": "Sárgaság - Mérsékelt támadás (állványok)",
  "yellowness_strong": "Sárgaság - Erős Támadás (teljesen érintett parcella)",
  "yellowness_without_symptoms": "Sárgaság - Tünetek nélkül",
  "zinc": "Cink"
}
