import React from 'react'
import AccountsLayout from '../../components/AccountComponents/AccountsLayout'
import useAccountsData from './useAccountsData'
import Organizations from '../OrganizationsContainer/Organizations'

const Accounts = ({ t }) => {
  const {
    accountsData,
    accountsError,
    isAccountsLoading,
    userPoliciesError,
    userPoliciesLoading,
    accountPoliciesError,
    accountPoliciesLoading,
    accountConfigLoading,
    accountConfigError
  } = useAccountsData()

  return (
    <>
      <Organizations t={t} />
      <AccountsLayout
        t={t}
        accounts={accountsData}
        isLoading={isAccountsLoading || userPoliciesLoading || accountPoliciesLoading || accountConfigLoading}
        error={accountsError || userPoliciesError || accountPoliciesError || accountConfigError}
      />
    </>
  )
}
export default Accounts
