// prettier-ignore
export default {
  "% Fallas": "% Zlyhania",
  "% Mala Hierba": "% Burina",
  "ACELGA": "MANGOLD",
  "ACELGA VERDE": "ZELENÝ MANGOLD",
  "action": "akcia",
  "ag_accesses_the_viewer": "k. AG prístup k prehliadaču?",
  "AGUACATE": "AVOKÁDO",
  "AJO": "CESNAK",
  "ALBARICOQUE": "MARHULA",
  "ALBARINO": "ALBARIŇO",
  "ALFALFA": "LUCERNA",
  "ALGODON": "BAVLNA",
  "aluminum": "Hliník",
  "aluminum_saturation": "Nasycenie Hliníkom",
  "annotation": "poznámka",
  "aphid_controller": "kontrola mšice",
  "aphid_infested": "infikované mšicami",
  "aphid_infested_leafs": "listy infikované mšicami",
  "aphid_mite_total_leafs": "celkový počet listov s mšicami a roztočmi",
  "APIO": "ZELER",
  "apparent_density": "Zdánlivá hustota",
  "ARANDANOS": "ČUČORIEDKY",
  "ARBOSANA": "ARBOSANA",
  "ARROZ": "RYŽA",
  "assesor_name": "Meno hodnotiteľa",
  "atr": "Atr",
  "AVENA": "OVOS",
  "BARBECHO": "ÚHOR",
  "BATAVIA": "BATAVIA",
  "beans_10_plants": "Rastliny 10 fazulí",
  "billaea_healthy_pupe": "zdravé kukly billaea",
  "billaea_larva": "larvy billaea",
  "billaea_parasitized_larva": "parazitované larvy billaea",
  "billaea_residual_pupe": "reziduálne kukly billaea",
  "bipolaris_sacchari_incidence": "incidencia bipolaris sacchari \"oko na stvole\"",
  "bipolaris_sacchari_severity": "závažnosť bipolaris sacchari \"oko na stvole\"",
  "bipolaris_sacchari_total_infected_leafs": "celkový počet infikovaných listov bipolaris sacchari \"oko na stvole\"",
  "BLANCO": "BIELE",
  "blastobasis_healthy_chrysalis": "zdravé chryzalídy blastobasis",
  "blastobasis_larva_number": "počet lariev blastobasis",
  "blastobasis_residual_chrysalis": "reziduálne chryzalídy blastobasis",
  "boron": "Bór",
  "bouquet10roots_2_8cm": "02.Kytica 10 koreňov (2-8cm)",
  "bouquet10roots_2cm": "01.Kytica 10 koreňov (0-2cm)",
  "bouquet10roots_8cm": "03.Kytica 10 koreňov (>8cm)",
  "brix": "Brix",
  "BROCOLI": "BROKOLICA",
  "bud_blastobasis_drilled": "duté puky diatraea blastobasis",
  "bud_dehydrated": "dehydrované výhonky",
  "bud_diatrea_drilled": "duté puky diatraea",
  "bud_mechanical_damage": "mechanické poškodenie pukov",
  "bud_sprout": "puky - výhonky",
  "bud_total": "celkový počet pukov",
  "bud_turgid": "šťavnaté puky",
  "CABERNET SAUVIGNON": "CABERNET SAUVIGNON",
  "CALABACIN": "CUKETA",
  "calcium": "Vápnik",
  "calcium_saturation": "Nasycenie Vápnikom",
  "CAÑA DE AZUCAR": "CUKROVÁ TRSTINA",
  "CAQUI": "KAKI",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Počet dospelých v 50 rastlinách",
  "casida_n_of_eggs_in_50_plants": "Cásida - Počet vajíčok v 50 rastlinách",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Počet veľkých lariev v 50 rastlinách",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Počet malých lariev v 50 rastlinách",
  "CAUCHO": "KAUČUK",
  "CEBADA": "JAČMEŇ",
  "CEBADA HIBRIDA": "HYBRIDNÝ JAČMEŇ",
  "CEBADA MALTERA": "SLADOVNÍCKY JAČMEŇ",
  "CEBOLLA": "CEBULA",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 škvŕn/list",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% listov ext s 80-100% závažnosťou",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Zdravá rastlina",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Izolované škvrny na niektorých listoch (10-50%SL/100 listov)",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Izolované škvrny na niektorých listoch (5-10%SL/100 listov)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Izolované škvrny na niektorých listoch (<5%SL/100 listov)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Listová nekróza EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Listy EXT: takmer úplná nekróza",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Listy s viac ako 100 škvrnami",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 škvrn/list",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Nekróza na väčšine listov INT.",
  "cercospora_treatment": "Cercospora -o- Liečba",
  "CEREZA": "ČEREŠŇA",
  "CESPED": "TRÁVNIK",
  "CHARDONNAY": "CHARDONNAY",
  "chromium": "Chróm",
  "cic": "Cic",
  "CIRUELA": "SLIVKA",
  "clay": "Íl",
  "CLEMENTINA": "KLEMENTÍNKA",
  "close": "zatvoriť",
  "COLZA": "REPKA",
  "Conteo": "Počet",
  "copper": "Meď",
  "cotesia_cocon": "cotesia - kokóny",
  "cotesia_parasitized_larva": "cotesia - parazitované larvy",
  "cotyledon": "kotylédón",
  "current_ndre_status": "a. Aktuálny stav NDRE",
  "date_of_first_informative_email_sent": "j. Dátum odoslania prvého informačného e-mailu",
  "date_of_hemav_ndre_layer_screenshot": "h. Dátum snímky NDRE vrstvy Hemav",
  "defoliator_leaf_evaluated": "defoliátor - hodnotené listy",
  "defoliator_marasmia_sp_larva_alive": "defoliátor - marasmia sp. - živé larvy",
  "defoliator_marasmia_sp_larva_death": "defoliátor - marasmia sp. - mŕtve larvy",
  "defoliator_marasmia_sp_larva_per_stem": "defoliátor - marasmia sp. - larvy na stonke",
  "defoliator_mythimna_sp_larva_alive": "defoliátor - mythimna sp. - živé larvy",
  "defoliator_mythimna_sp_larva_death": "defoliátor - mythimna sp. - mŕtve larvy",
  "defoliator_mythimna_sp_larva_per_stem": "defoliátor - mythimna sp. - larvy na stonke",
  "defoliator_spodoptera_sp_larva_alive": "defoliátor - spodoptera sp. - živé larvy",
  "defoliator_spodoptera_sp_larva_death": "defoliátor - spodoptera sp. - mŕtve larvy",
  "defoliator_spodoptera_sp_larva_per_stem": "defoliátor - spodoptera sp. - larvy na stonke",
  "defoliator_stem_total": "defoliátor - celkový počet stoniek",
  "depth": "Hĺbka",
  "desconocido": "Neznáme",
  "DESCONOCIDO": "NEZNÁME",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - veľké larvy",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - zdravé chryzalídy",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - stredné larvy",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - reziduálne chryzalídy",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - malé larvy",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - celkový počet lariev",
  "disease": "Choroba",
  "Disease": "Choroba",
  "disease_level": "08.Uroveň choroby",
  "distance_between_rows": "03.Vzdialenosť medzi radmi",
  "does_it_follow_the_recommendation": "f. Sleduje to odporúčanie?",
  "Drip": "Kvapkanie",
  "dry_weight": "Suchá hmotnosť",
  "effective_cation_exchange_capacity": "Efektívna Kapacita Výmeny Katiónov (EKVK)",
  "ESCAROLA": "ENDÍVIA",
  "ESCAROLA LISA": "HLADKÁ ENDÍVIA",
  "ESCAROLA RIZADA": "KADERAVÁ ENDÍVIA",
  "ESPARRAGO": "ŠPARGĽA",
  "ESPINACA": "ŠPENÁT",
  "estrés hídrico": "Vodný stres",
  "Estrés hídrico (LWS)": "Vodný stres (LWS)",
  "evaluator": "10.Meno hodnotiteľa",
  "Ex": "Ex.",
  "exchangeable_acidity": "Výmenná Kyslosť",
  "forecast_yield_sampling_1": "PROGNÓZA VÝNOSU VZORKOVANIE 1",
  "forecast_yield_sampling_2": "PROGNÓZA VÝNOSU VZORKOVANIE 2",
  "forecast_yield_sampling_3": "PROGNÓZA VÝNOSU VZORKOVANIE 3",
  "forecast_yield_sampling_4": "PROGNÓZA VÝNOSU VZORKOVANIE 4",
  "fresh_weight": "Čerstvá hmotnosť",
  "FRIJOL": "FAZUĽA",
  "fruits_count": "Počet ovocia",
  "FRUTAL": "OVOCNÝ",
  "Generic": "Generický",
  "GEWURZTRAMINNER": "GEWURZTRAMINER",
  "GIRASOL": "SLNEČNICA",
  "gm_avg_ndvi": "Vegetatívny rast (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Vegetatívny rast (NDVI-LUVI)",
  "gm_ndmi_mean": "Vegetatívna vlhkosť (NDMI)",
  "gm_ndre_mean": "Dusík-Chlorofyl (NDRE)",
  "gm_perc_overseeding": "% Medzery",
  "gm_perc_weeds": "% Burina",
  "GODELLO": "GODELLO",
  "GOLDEN DELICIOUS": "ZLATÝ DELICIOUS",
  "GOLDEN REINDERS": "ZLATÝ REINDERS",
  "Grams": "Gramy",
  "GRANADA": "GRANÁT",
  "green_dots": "zelené bodky",
  "GRENACHE BLANC": "GRENACHE BLANC",
  "GUISANTE": "HRACH",
  "HABA": "BOB",
  "height": "Výška",
  "HIBRIDO": "HYBRID",
  "high irrigation": "Vysoký vodný stres",
  "high_n_from_irrigation_water": "d. Vysoký dusík z irigačnej vody",
  "high_n_from_nitrogen_input": "e. Vysoký dusík z prísunu dusíka",
  "high_n_from_previous_years": "c. Vysoký dusík z predchádzajúcich rokov",
  "higher level": "Vyššia úroveň",
  "HIGO": "FIGA",
  "Humedad Vegetativa (NDMI)": "Vegetatívna vlhkosť (NDMI)",
  "humidity": "Vlhkosť",
  "humidity_100_beans": "Vlhkosť v 100 fazuliach",
  "Imperial": "Imperiálne",
  "inspection": "inšpekcia",
  "internodes_blastobasis_damage_longitude": "dĺžka poškodenia internódiami blastobasis",
  "internodes_blastobasis_drilled": "perforované internódy blastobasis",
  "internodes_diatraea_damage_longitude": "dĺžka poškodenia internódiami diatrea",
  "internodes_diatraea_drilled": "perforované internódy diatrea",
  "internodes_evaluated": "celkový počet internód",
  "internodes_healthy": "zdravé internódy",
  "internodes_mechanical_damage": "mechanické poškodenie internód",
  "internodes_physiological_damage": "fyziologické poškodenie internód",
  "iron": "Železo",
  "irrigation": "ZAVLAŽOVANIE",
  "Irrigation": "Zavlažovanie",
  "irrigation_dosage": "Dávkovanie zavlažovania",
  "irrigation_uniformity": "Uniformita zavlažovania",
  "KUMQUATS": "KUMKVATY",
  "language": "Jazyk systému",
  "larva_number_big": "larválne štádium - veľká larva",
  "larva_number_medium": "larválne štádium - stredná larva",
  "larva_number_small": "larválne štádium - malá larva",
  "last_fertilizer_app_date": "b. Dátum poslednej aplikácie hnojiva",
  "last_irrigation": "Posledné zavlažovanie",
  "lead": "Olovo",
  "Leaf": "List",
  "leaf_moisture_status": "Stav vlhkosti listu",
  "LECHUGA": "ŠALÁT",
  "LECHUGA ICEBERG": "ICEBERG ŠALÁT",
  "leptosphaeria_sacchari_incidence": "incidencia leptosphaeria sacchari \"prstenec na stvole\"",
  "leptosphaeria_sacchari_severity": "závažnosť leptosphaeria sacchari \"prstenec na stvole\"",
  "leptosphaeria_sacchari_total_infected_leafs": "celkový počet infikovaných listov leptosphaeria sacchari \"kruhová škvrna\"",
  "LIMON": "CITRÓN",
  "linear_meters": "02.Lineárne metre",
  "loam": "Ílovitá pôda",
  "low irrigation": "Nízky vodný stres",
  "lower level": "Nižšia úroveň",
  "lowered_unload": "03.Snížené pri vykládaní",
  "MACABEU": "MACABEU",
  "MACABEU VIURA": "MACABEU VIURA",
  "magnesium": "Horčík",
  "magnesium_saturation": "Nasycenie Horčíkom",
  "MAIZ": "KUKURICA",
  "MALVASIA AROMATICA": "AROMATICKÁ MALVÁZIA",
  "MALVASIA DE SITGES": "MALVÁZIA DE SITGES",
  "MALVASIA GROSSA": "HRUBÁ MALVÁZIA",
  "MANDARINA": "MANDARÍNKA",
  "manganese": "Mangán",
  "MANZANA": "JABĹČKO",
  "mean level": "Priemerná úroveň",
  "medium irrigation": "Stredný vodný stres",
  "medium_moisture_saturation": "Nasýtenie Strednej Vlhkosti",
  "MELOCOTON": "BROSKYŇA",
  "MELON": "MELÓN",
  "mercury": "Ortuť",
  "MERLOT": "MERLOT",
  "Metric": "Metrický",
  "microorganisms_larva": "larvy mikroorganizmov",
  "mite_grade": "stredný stupeň roztočov",
  "mite_infested": "infikované roztočmi",
  "mite_infested_leafs": "listy infikované roztočmi",
  "moisture_status": "07.Stav vlhkosti",
  "molybdenum": "Molybdén",
  "MONTONEC": "MONTONEC",
  "MONTONEGA": "MONTONEGA",
  "MOSCATELL DE ALEXANDRIA": "MUSKÁT ALEXANDRIA",
  "MOSCATELL ROMA": "MUSKÁT RÍM",
  "NARANJA": "POMARANČ",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "NEKTARINKA",
  "NECTARINA": "NEKTARINKA",
  "nickel": "Nikel",
  "Nitrogen": "Dusík",
  "Nitrógeno Foliar": "Listový dusík",
  "nitrógeno-clorofila (ndre)": "Dusík-Chlorofyl (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Dusík-Chlorofyl (NDRE)",
  "No crop or too dry": "Žiadna úroda alebo príliš suché",
  "NO CULTIVO": "ŽIADNA ÚRODA",
  "No hay datos": "Žiadne údaje",
  "NOGAL": "ORECH",
  "notification_to_the_farmer_high_n_index": "g. Upozornenie farmárovi: Vysoký index dusíka",
  "Observations": "Pozorovania",
  "OLIVO": "OLIVA",
  "opogona_larva_number": "larvy opogona",
  "organic_material": "Organický materiál",
  "organic_oxidizable_carbon": "Organický Oxidovateľný Uhlík",
  "PANSA BLANCA": "BIELA PANSA",
  "PANSAL": "PANSAL",
  "PARAGUAYO": "PARAGUAJSKÝ",
  "PARELLADA": "PARELLADA",
  "PATATA": "ZEMIAK",
  "pattern": "vzor",
  "PERA": "HRUŠKA",
  "percent_disease": "Percento choroby",
  "perkinsiella_345_instar_nymph": "perkinsiella - nymfy 3-4-5 instar",
  "perkinsiella_adult": "perkinsiella - dospelé",
  "perkinsiella_controller_coccinelidos": "perkinsiella - regulátory - lienky",
  "perkinsiella_controller_crysopas": "perkinsiella - regulátory - zlatooké",
  "perkinsiella_controller_spiders": "perkinsiella - regulátory - pavúky",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - vodiči - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - vodiči - zelus",
  "perkinsiella_death_adult": "perkinsiella - dospelé mŕtve",
  "perkinsiella_death_nymph": "perkinsiella - mŕtve nymfy",
  "petiole_sampling": "i. Vzorkovanie petiolu",
  "ph": "Ph",
  "pheromone_trap_diatraea_adults_number": "feromónová pasca - dospelé diatraea",
  "pheromone_trap_installation_date": "feromónová pasca - dátum inštalácie",
  "pheromone_trap_number": "feromónová pasca - pasce",
  "pheromone_trap_other_butterfly": "feromónová pasca - iné motýle",
  "pheromone_trap_pheromone_change": "feromónová pasca - zmena feromónu",
  "phosphorus": "Fosfor",
  "PIMIENTO": "PAPRIKA",
  "PINO": "BOROVICA",
  "PINOT NOIR": "PINOT NOIR",
  "PISTACHO": "PISTÁCIA",
  "Pivot": "Pivot",
  "Plague": "Mor",
  "plague_level": "09.Uroveň moru",
  "Plant": "Rastlina",
  "Plant Density": "Hustota rastlín",
  "plantation_date": "Dátum výsadby",
  "plants_per_surface_unit": "Rastliny na jednotku plochy",
  "poda": "Rez",
  "pol": "Pol",
  "POMELO": "GREP",
  "POMELOS": "GREPY",
  "potassium": "Draslík",
  "potassium_saturation": "Nasycenie Draslíkom",
  "powdery_mildew_afa_perc": "Múčnatka - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Múčnatka - % postihnutých rastlín (INCIDENCIA)",
  "powdery_mildew_treatment": "Múčnatka - Liečba",
  "Production": "Produkcia",
  "pseudomonas_rubrilineans_stem_number": "červený pruh - celkový počet stoniek",
  "pseudomonas_rubrilineans_stem_number_affected": "červený pruh - postihnuté stonky",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "červený pruh - stonky s príznakmi",
  "puccinia_melanocephala_accumulated_scale": "hrdzavosť - kumulatívna škála",
  "puccinia_melanocephala_infestation": "hrdzavosť-infestácia",
  "puccinia_melanocephala_infested_leafs": "hrdzavosť-infikované listy",
  "puccinia_melanocephala_points": "hrdzavosť - body",
  "puccinia_melanocephala_total_leafs": "hrdzavosť - celkový počet listov",
  "puccinia_sp_incidence": "puccinia sp \"roya\" - incidencia",
  "puccinia_sp_severity": "puccinia sp \"roya\" - závažnosť",
  "puccinia_sp_total_infected_leafs": "puccinia sp \"roya\" - celkový počet infikovaných listov",
  "PUERRO": "PÓR",
  "Quality": "Kvalita",
  "QUINOA": "QUINOA",
  "RAIGRAS": "RAIGRAS",
  "real_leaves": "skutočné listy",
  "REMOLACHA": "CUKROVÁ REPA",
  "REMOLACHA AZUCARERA": "CUKROVÁ REPA",
  "resowing_surface": "Plocha presevu",
  "result": "výsledok",
  "riego": "Zavlažovanie",
  "rot10roots_complete": "03.Hniloba 10 koreňov - Kompletná",
  "rot10roots_nothing": "01.Hniloba 10 koreňov - Nič",
  "rot10roots_partial": "02.Hniloba 10 koreňov - Čiastočná",
  "rust_10_25_afa": "Hrdza - 10-25 % AFA",
  "rust_100_afa": "Hrdza -N- 100 % AFA. Pôvodné lístie úplne zničené",
  "rust_25_35_afa": "Hrdza -J- 25-35 % AFA. 1/4 -1/3 lístia je vážne postihnuté",
  "rust_3_10_afa": "Hrdza -I- 3-10 % AFA",
  "rust_35_50_afa": "Hrdza -K- 35-50 % AFA.  1/3 - 1/2 lístia je vážne postihnuté",
  "rust_50_75_afa": "Hrdza -L- 50-75 % AFA. 1/2 lístia je vážne postihnuté",
  "rust_75_90_afa": "Hrdza -M- 75-90 % AFA. Všetko lístie je vážne postihnuté",
  "rust_healthy_plant": "Hrdza -A- Zdravá rastlina",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Hrdza -B- Izolované škvrny na niektorých listoch. 1-9 (%SL)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Hrdza -C- Izolované škvrny na niektorých listoch. 10-19 (%SL)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Hrdza -D- Izolované škvrny na niektorých listoch. 20-29 (%SL)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Hrdza -E- Izolované škvrny na niektorých listoch. 30-50 (%SL)",
  "rust_regrowth_restart": "Hrdza -O- Začiatok obnovy rastu. Pridajte 0,5 bodov za každý týždeň až do zberu",
  "rust_spots_leaves_100_300": "Hrdza -H- Listy s 100-300 škvrnami",
  "rust_spots_leaves_20_100": "Hrdza -G- 20-100 škvrn na list na väčšine listov",
  "rust_spots_leaves_50": "Hrdza -F- >50% SL a <20 škvrn na list na väčšine listov",
  "rust_treatment": "Hrdza -p- Liečba",
  "sac": "Vrec",
  "Safety": "Bezpečnosť",
  "sample_color": "05.Farba vzorky",
  "sand": "Piesok",
  "SAUVIGNON BLANC": "SAUVIGNON BLANC",
  "severity": "Závažnosť",
  "soca_width": "04.Počet koreňov",
  "sodium": "Sodík",
  "sodium_saturation": "Nasycenie Sodíkom",
  "Soil": "Pôda",
  "soil_moisture_status": "Stav pôdnej vlhkosti",
  "SOJA": "SÓJA",
  "sprout_dead": "mŕtvy výhonok",
  "sprout_total": "celkový počet výhonkov",
  "starch": "Škrob",
  "stems_evaluated": "hodnotené stonky",
  "sulfur": "Síra",
  "Superficial": "Povrchový",
  "SYRAH": "SYRAH",
  "TABACO": "TABAK",
  "TE": "ČAJ",
  "TEA": "FAKĽA",
  "TEMPRANILLO": "TEMPRANILLO",
  "TOMATE": "RAJČIAK",
  "total_arsenic": "Celkový Arzén",
  "total_cadmium": "Celkový Kadmium",
  "TOURIGA NACIONAL": "NÁRODNÁ TOURIGA",
  "TRIGO": "PŠENICA",
  "TRIGO BLANDO": "MÄKKÁ PŠENICA",
  "TRIGO DURO": "TVRDÁ PŠENICA",
  "TRITICALE": "TRITICALE",
  "type_disease": "Typ choroby",
  "ustilago_scitaminea_infection": "ustilago scitaminea \"uhlie\" - infekcia",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"uhlie\" - celkový počet chorých stoniek",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"uhlie\" - stonky s príznakmi",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"uhlie\" - celé stonky",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"uhlie\" - bičové stonky",
  "UVA": "HRONO",
  "UVA DE MESA": "STOLOVÉ HRONO",
  "Variabilidad": "Variabilita",
  "Variabilidad (NDVI-LUVI)": "Variabilita (NDVI-LUVI)",
  "VERDEJO": "ZELENÉ",
  "very high irrigation": "Veľmi vysoký vodný stres",
  "very low irrigation": "Veľmi nízky vodný stres",
  "VIGNE": "VIGNE",
  "Vigor Vegetativo (NDVI-Drone)": "Vegetatívny rast (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vegetatívny rast (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vegetatívny rast (NDVI) - Vysoký rast",
  "VIÑA": "VINICA",
  "VIOGNIER": "VIOGNIER",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "pasca panny - celkový počet dospelých diatraea",
  "virgin_female_georeferenced_trap_female_change": "pasca panny - zmena samice",
  "virgin_female_georeferenced_trap_other_butterfly": "pasca panny - iné motýle",
  "virgin_female_georeferenced_trap_point": "pasca panny - bod pasce",
  "virgin_female_trap_diatraea_adult_number": "pasca panny - dospelé diatraea",
  "virgin_female_trap_female_change": "pasca panny - zmena samice",
  "virgin_female_trap_installation_date": "pasca panny - dátum inštalácie",
  "virgin_female_trap_number": "pasca panny - pasce",
  "virgin_female_trap_other_butterfly": "pasca panny - iné motýle",
  "VIURA": "VIURA",
  "weed_unload": "01. Buriny (vykládka)",
  "Weeds": "Buriny",
  "weight_kg": "06.Hmotnosť vzorky",
  "weight_100_beans": "Rastliny 100 fazulí",
  "whey": "Syrovátka",
  "without_footwear": "01.Bez obuvi",
  "without_gloves": "04.Bez rukavíc",
  "without_vest": "02.Bez vesty",
  "XARELLO": "XARELLO",
  "yellowness_mild": "Žltnutie - Mierne útok (voľné rastliny)",
  "yellowness_moderate": "Žltnutie - Stredný útok (stojany)",
  "yellowness_strong": "Žltnosť - Silný útok (parcela úplne postihnutá)",
  "yellowness_without_symptoms": "Žltosť - Bez príznakov",
  "zinc": "Zinok"
}
